import React from 'react'

function Inputtt({placeholder, label, type, onChange, value, name}) {
  return (
    <div className=' my-2'>
    <label className=' block mb-3 text-back-color2'>{label}</label>
        <input 
            className=' w-full bg-white outline-0 border border-[#343373] p-3 rounded-lg'
            placeholder={placeholder}
            type={type}
            name={name}
            required
            // onChange={(e) => onChange(e.target.value)}
            // value={value}
        />
    </div>
  )
}

export default Inputtt