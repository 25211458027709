import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <div className=' sm:px-[90px] border-t border-f-border flex justify-between  px-8 py-3'>
        <p className='text-[#343373] font-[500] text-[12px] sm:text-[16px]'>© Kelen Immigration Services Inc.</p>
        <p className='text-[#343373] font-[500] text-[12px] sm:text-[16px]'><Link to={'/privacy'}>Privacy Policy</Link> </p>
    </div>
  )
}

export default Footer