import React from 'react'
import Navbar from '../../Components/Navbar'
import TopSec from '../../Components/Home/Topsec'
import About from '../../Components/Home/About'
import Services from '../../Components/Home/Services'
import Reviews from '../../Components/Home/Reviews'
import Contact from '../../Components/Home/Contact'
import Footer from '../../Components/Footer'

function Home() {
  return (
    <div>
      <Navbar/>
      <div className=' pt-[80px]'></div>
      <TopSec/>
      <About/>
      <Services/>
      <Reviews/>
      <Contact/>
      <Footer/>
    </div>
  )
}

export default Home