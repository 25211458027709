import React from 'react'
import Profile from '../../images/cco.png'
import Sig from '../../images/signature_1i.png'
function About() {
  return (
    <div id='about' className=' sm:px-[90px] sm:py-[96px] bg-customCyan py-[48px] px-8 grid sm:grid-cols-5 gap-9'>
    <div className=' col-span-3'>
        {/* <h1 className=' text-xl mb-3 font-bold text-primary-color'>About</h1>
        <p className='text-xl mb-2 text-primary-color2 '>Regulated Canadian Immigration Consultant</p>
        <div className=' w-[100px] mb-3 h-1 rounded-full bg-primary-color'></div> */}
        <p className='text-[18px] font-[400] mb-2 text-primary-color2 '>
        Navigating the Canadian immigration system can be far more complex than it seems. While the Government of Canada’s website provides general guidance, it often overlooks the intricacies involved in non-standard situations. The process is filled with specific requirements and potential pitfalls, where even a single mistake could result in a rejected application or even a ban from entering Canada.
<br></br> <br></br>
Think of the government's website as a travel brochure that highlights the destination but leaves out the challenges of the journey. That’s where I come in. My role is to guide you through the complex process, helping you avoid common errors and ensuring your application is as seamless and successful as possible.
<br></br> <br></br>
With over 20 years of experience in Canadian immigration, I have in-depth knowledge of the process, from straightforward applications to the most complicated cases. I know how to tackle the challenges and find solutions that work.
<br></br> <br></br>
Let my expertise help you achieve your immigration goals. Get in touch today, and we’ll begin crafting a personalized plan to move you from where you are to where you want to be. <span className=' text-primary-color'><a href='#contact'>Contact us today.</a></span>

        </p>
        <img src={Sig} alt='Signature' width={200} height={170} className='mx-auto'/>
    </div>
    <div className='col-span-2'>
        <div className='  relative'>
        {/* <div className=' w-[50%]  hidden sm:block sm:absolute bottom-[130px] rounded-lg right-[135px] bg-[#C3C1C1] p-2 sm:p-6 '>
                <h1 className=' text-sm sm:text-[28px] mb-1 text-primary-color2'>20+ Years</h1>
                <p>Experience Immigration Services</p>
            </div> */}
            <img src={Profile} height={300} width={300} alt='profile'/>
            <p className=' text-[20px] font-[700] text-[#3A3A3A] text-start my-[15px] sm:text-[32px]'>Kerry Molitor, RCIC-IRB</p>
            <p className='text-[14px] font-[400] text-[#3A3A3A] text-start mb-[6px] sm:text-[18px]'>Member of the College of Immigration and Citizenship Consultants (CICC)</p>
            <p className='text-[14px] font-[400] text-[#3A3A3A] text-start mb-[6px] sm:text-[18px]'>Commissioner for Taking Affidavits (Ontario)</p>
        </div> 
    </div>
    </div>
  )
}

export default About