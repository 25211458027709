import React from 'react'
import Google from '../../images/klgoo.svg'
function ReviewCom({img, con, name, show}) {
  return (
    <div className=' relative shadow-xl rounded-[40px] w-[95%] my-16  bg-white border border-review-border pt-[102px] px-[10px] sm:px-[32px] pb-[56px]'>
        <p className=' text-center font-[400] text-[16px] text-[#344054] mb-[24px]'>{con}</p>
        <div className=' flex gap-3 justify-between'>
        <p className=' text-primary-color  font-[500] text-[18px]'>{name}</p>
        {show === true && <img src={Google} alt='google'/>}
        </div>
        
         <img src={img} width={100} height={100} alt={name} className=' absolute -top-14 left-[37%]'/>
        
    </div>
  )
}

export default ReviewCom