import React from 'react'

function Content() {
  return (
    <div className=' mt-10 px-8 sm:px-20'>
       <p className=' font-[400] mb-4 text-[20px]'>
       At Kelen Immigration Services, we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard the personal information you submit through our website.
      </p>
      <ol className="list-decimal list-inside space-y-2">
        <li className=' font-[400] mb-3 text-[18px] text-[#3A3A3A]'><span className=' font-[500] text-[18px] text-[#3A3A3A]'>Information Collection:</span>  We may collect personal information, including but not limited to your name, email address, contact information, and any other details you voluntarily provide when using our website.</li>
        <li className=' font-[400] mb-3 text-[18px] text-[#3A3A3A]'><span className=' font-[500] text-[18px] text-[#3A3A3A]'>Use of Information:</span> The information we collect will be used solely for the purpose for which it was provided, including responding to inquiries, providing services, or improving user experience. We will not sell, rent, or share your personal information with third parties without your explicit consent, unless required by law.</li>
        <li className=' font-[400] mb-3 text-[18px] text-[#3A3A3A]'><span className=' font-[500] text-[18px] text-[#3A3A3A]'>Data Security:</span> We implement appropriate security measures to protect your personal information from unauthorized access, alteration, or disclosure. However, no method of transmission over the internet is completely secure, and we cannot guarantee absolute security.</li>
        <li className=' font-[400] mb-3 text-[18px] text-[#3A3A3A]'><span className=' font-[500] text-[18px] text-[#3A3A3A]'>Cookies:</span> Our website may use cookies to enhance user experience. Cookies are small files stored on your device that help us improve website functionality. You can adjust your browser settings to refuse cookies or notify you when they are being used.</li>
        <li className=' font-[400] mb-3 text-[18px] text-[#3A3A3A]'><span className=' font-[500] text-[18px] text-[#3A3A3A]'>Third-Party Links:</span> Our website may contain links to third-party sites. We are not responsible for the privacy practices or content of these sites.</li>
        <li className=' font-[400] mb-3 text-[18px] text-[#3A3A3A]'><span className=' font-[500] text-[18px] text-[#3A3A3A]'>Changes to Privacy Policy:</span> We reserve the right to modify this Privacy Policy at any time. Changes will be posted on this page, and your continued use of the website signifies acceptance of any updates.</li>
        <li className=' font-[400] mb-3 text-[18px] text-[#3A3A3A]'><span className=' font-[500] text-[18px] text-[#3A3A3A]'>Contact Information:</span>  If you have any questions or concerns regarding this Privacy Policy, please contact us at <span className=' text-primary-color'>info@kelenservices.com.</span> </li>
      </ol>

 <div className='font-[400] mb-[48px] mt-3 text-[20px] '>
 By using our website, you agree to the terms outlined in this Privacy Policy.
 </div>

       
    </div>
  )
}

export default Content