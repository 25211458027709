import React from 'react'
import P1 from '../../images/vv1.png'
import P2 from '../../images/vv2.png'
import P3 from '../../images/vv3.png'

function Services() {
    const visaList = [
        { title: 'Visitor Visas & Records', description: 'Enter Canada and extend your stay' },
        { title: 'Super Visas', description: 'Invite your parents and grandparents to visit long-term' },
        { title: 'Invitation Letters', description: 'Invite someone to visit you in Canada' },
        { title: 'Study Permits', description: 'Pursue an education at the elementary, secondary, or post-secondary level' },
        { title: 'Post - Graduate Work Permits', description: 'Work in Canada after post-secondary graduation' },
        { title: 'Open Work Permits', description: 'Work in most jobs in Canada' },
       // { title: 'Nafta Work Permits', description: 'Americans and Mexicans can work with specific employers' },
        //{ title: 'LMIAs', description: 'Government permission for businesses to hire foreign workers' },
        { title: 'LMIA - Based Work Permits', description: 'Employer-specific work permits' }
      ];

      const immigrationList = [
        { title: 'Federal Skilled Workers (Express Entry)', description: 'For international professionals' },
        { title: 'Canadian Experience Class (Express Entry)', description: 'For professionals with Canadian work experience' },
        { title: 'Provincial Nominees', description: 'For people selected for permanent residence by a Province or Territory' },
        { title: 'Spouse & Common-Law Sponsorships', description: 'Help your spouse or partner immigrate' },
        { title: 'Parent & Grandparent Sponsorships', description: 'Help your parents and grandparents immigrate' },
        { title: 'Child Sponsorships', description: 'Help your children immigrate' },
        { title: 'Other Relative Sponsorships', description: 'Help other relatives immigrate (allowed under special circumstances)' },
        { title: 'Humanitarian & Compassionate', description: 'For people who deserve to stay in Canada for exceptional reasons' },
        //{ title: 'Pre-Removal Risk Assessment', description: 'For people in danger abroad who are about to be deported' }
      ];

      const otherServicesList = [
        { title: 'Criminal Rehabilitation', description: 'Permanently overcome a foreign criminal record for immigration' },
      //  { title: 'Canadian Record Suspensions', description: 'Help with the Parole Board of Canada' },
        { title: 'Temporary Resident Permits', description: 'Temporarily overcome an inadmissibility' },
        { title: 'Medical Inadmissibility', description: 'Permanently overcome a medical inadmissibility' },
        { title: 'Citizenship', description: ' Apply for citizenship and proof of citizenship' },
        { title: 'Residency Questionnaires', description: 'Address complications with citizenship or permanent residence' },
        { title: 'Replacing Documents', description: 'Renew Permanent Resident Cards or correct mistakes on documents' },
        { title: 'Affidavits & Statutory Declarations', description: 'Swear statements for evidence' },
        { title: 'Access To Information Requests', description: 'Learn the details about why an application was refused' },
        { title: 'Refugee Permanent Residence', description: 'Applications for Convention Refugees' },
        { title: 'Others', description: 'Many other services available upon request' },
      ];

  return (
    <div id='service' className='sm:py-[96px] py-[48px]'>
    <div className='sm:px-[90px]  px-8 '>
            <h1 className=' font-[900] text-[28px] sm:text-[46px] text-primary-color text-center sm:w-[75%] mx-auto'>What Kelen Immigration Services Can Do For You</h1>
            <div className=' grid sm:grid-cols-2 gap-10 py-[50px]'>
                <div>
                    <img src={P1} alt='P1'/>
                </div>
                <div className=' sm:w-[75%] mx-auto'>
                    <h1 className=' font-[700] text-[#3A3A3A] text-[32px]'>Visit, Study, Work</h1>
                    <div className='w-[85%] sm:w-[320px] mb-3 h-1 rounded-full bg-primary-color'></div>
                    <p className=' font-[400] mb-4 text-[20px]'>Kelen Immigration Services offers the following:</p>
                    {visaList.map((e,i) =>{
                        return <ul className="list-disc list-inside space-y-2" key={i}>
                            <li className=' font-[400] mb-3 text-[18px] text-[#3A3A3A]'><span className=' font-[500] text-[18px] text-[#3A3A3A]'>{e.title}</span> - {e.description}</li>
                        </ul>
                    })}
                </div>
            </div>
    </div>
    <div className=' w-full h-2 bg-[#F5F5F5]'></div>
    <div className='sm:px-[90px]  px-8 '>
            <div className=' grid sm:grid-cols-2 gap-10 py-[50px]'>
               
                <div className=' sm:w-[75%] mx-auto'>
                    <h1 className=' font-[700] text-[#3A3A3A] text-[32px]'>Stay</h1>
                    <div className='w-[20%] sm:w-[120px] mb-3 h-1 rounded-full bg-primary-color'></div>
                    <p className=' font-[400] mb-4 text-[20px]'>Kelen Immigration Services offers the following:</p>
                    {immigrationList.map((e,i) =>{
                        return <ul className="list-disc list-inside space-y-2" key={i}>
                            <li className=' font-[400] mb-3 text-[18px] text-[#3A3A3A]'><span className=' font-[500] text-[18px] text-[#3A3A3A]'>{e.title}</span> - {e.description}</li>
                        </ul>
                    })}
                </div>
                <div>
                    <img src={P2} alt='P2'/>
                </div>
            </div>
    </div>
    
    <div className=' w-full h-2 bg-[#F5F5F5]'></div>
    <div className='sm:px-[90px]  px-8 '>
            <div className=' grid sm:grid-cols-2 gap-10 py-[50px]'>
            <div>
                    <img src={P3} alt='P3'/>
                </div>
                <div className=' sm:w-[75%] mx-auto'>
                    <h1 className=' font-[700] text-[#3A3A3A] text-[32px]'>Other</h1>
                    <div className=' w-[20%] sm:w-[120px] mb-3 h-1 rounded-full bg-primary-color'></div>
                    <p className=' font-[400] mb-4 text-[20px]'>Kelen Immigration Services offers the following:</p>
                    {otherServicesList.map((e,i) =>{
                        return <ul className="list-disc list-inside space-y-2" key={i}>
                            <li className=' font-[400] mb-3 text-[18px] text-[#3A3A3A]'><span className=' font-[500] text-[18px] text-[#3A3A3A]'>{e.title}</span> - {e.description}</li>
                        </ul>
                    })}
                </div>
               
            </div>
    </div>
    
    </div>
  )
}

export default Services