import React from 'react'
import ReviewSllider from './ReviewSlider'


function Reviews() {
  return (
    <div id='review' className='sm:py-[96px] w-full py-[48px] sm:px-[90px]  px-4 bg-customCyan'>
        <div className=' sm:w-[45%] flex mx-auto justify-center'>
        <div className=' mb-5'>
         <h1 className=' text-primary-color text-[32px] sm:text-[48px] text-center font-[900] mb-2'>What Clients Say</h1>
       <div className=' sm:mx-16 sm:w-[250px] mb-3 h-1 rounded-full bg-primary-color'></div>
        </div>
       
        </div>
        <ReviewSllider/>
       
    </div>
  )
}

export default Reviews