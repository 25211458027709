import React, { useEffect, useState } from 'react'
import Logo from '../images/Logo_INC_MASTER_Colour.jpg'
import { Link } from 'react-router-dom';
function Navbar() {
    const [navbar, setNavbar] = useState(false);
    const [clientWindowHeight, setClientWindowHeight] = useState("");
    const [changeBg, setChangeBg] = useState(false)
    const [activeSection, setActiveSection] = useState('');
    useEffect(() => {
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    });
   const active = 'px-2 py-1 ml-[21px]  transition ease-in-out active text-[16px] border-[#343373] font-[300] border-2 rounded-[12px] text-[#343373]'
   const inActive = 'px-2 py-1 transition ease-in-out ml-[21px] text-[#8F8F8F] font-[300] text-[16px] hover:border-[#343373] hover:border hover:rounded-[12px] hover:text-[#343373]'
    const handleScroll = () => {
      setClientWindowHeight(window.scrollY);
    };
  
    useEffect(() => {
      let backgroundTransparacyVar = clientWindowHeight / 610;
      if (backgroundTransparacyVar < 1) {
        setChangeBg(true)
      }
      else {
        setChangeBg(false)
      }
    }, [clientWindowHeight])
    const [activeItem, setActiveItem] = useState(null);

    const handleNavClick = (id) => {
      setActiveItem(id); // Set the clicked item's id as active
    };
    useEffect(() => {
      const currentHash = window.location.hash;
      if (currentHash) {
        setActiveItem(currentHash);
      }
    }, []);
    useEffect(() => {
      const sections = document.querySelectorAll('section');
  
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setActiveSection(entry.target.id);
            }
          });
        },
        { threshold: 0.4 }
      );
  
      sections.forEach((section) => {
        observer.observe(section);
      });
  
      return () => {
        sections.forEach((section) => {
          observer.unobserve(section);
        });
      };
    }, []);
  return (
    <div className={changeBg ? `w-full  bg-white fixed  z-40    ` : `w-full z-40 fixed  shadow-lg bg-white   `}>
        <div className="">
        <div>
          
          
            <div className="  flex items-center justify-between px-[36px] sm:px-[86px] py-3 md:py-5">
            <Link to='/' >
              <img src={Logo} width='300' height='300' className=" inline-block pl-6 " alt=" logo" />
            </Link>
              <div className=" hidden sm:flex flex-col pr-3 justify-center">
              <div className='flex'>
                <ul className=' flex navlink'>
                  <a
                  href='#about'
                  className={activeSection === "home" ? 'active' :inActive}
                  onClick={() => handleNavClick("home")}
                  >
                  About
                  </a>
                   <a
                  href='#service'
                  className={activeSection === "service" ? 'active' : inActive}
                  onClick={() => handleNavClick("service")}
                  >
                  Services
                  </a>
                  <a
                   href='#review'
                  className={activeSection === "review" ? 'active' : inActive}
                  onClick={() => handleNavClick("review")}
                  >
                  Reviews
                  </a>
                
                  <a
                  href='#contact'
                  className={activeSection === "contact" ? 'active' :inActive}
                  onClick={() => handleNavClick("contact")}
                  >
                  Contact
                  </a>

                </ul>
              </div>
              </div>
            </div>
         
      
       
        </div>

        </div>
    </div>
  )
}

export default Navbar