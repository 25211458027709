
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ReviewCom from './ReviewCom'
import R1 from '../../images/rev1.png'
import R2 from '../../images/rev2.png'
import R3 from '../../images/rev3.png'
import R4 from '../../images/r4.png'
import R5 from '../../images/r5.png'
import R6 from '../../images/r6.png'
import Af from '../../images/af.svg'
import Ab from '../../images/sb.svg'

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      
      onClick={onClick}
    >
    <img className={className} src={Af} alt="Af"/>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
     
      onClick={onClick}
    >
    <img className={className} src={Ab} alt="Ab"/>
    </div>
  );
}

function Responsive() {
  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 2,
    initialSlide: 0,
    autoplay: false,
    speed: 2000,
    autoplaySpeed: 5000,
    cssEase: "linear",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div className="slider-container mx-5">
      <Slider {...settings}>
        <div >
        <ReviewCom
                img={R1}
                con={" 'My husband and I decided to hire Ms. Molitor from Kelen Immigration Services and don't regret it. She's totally competent, professional, organized, available and comprehensive about my family needs. She guided us through the best path to get the Canadian permanent residence and we obtained it in the easiest way.' "}
                name={'Eli and Leo '}
                show={false}
        />
        </div>
        <div>
        <ReviewCom
                img={R3}
                con={" 'Thanks to Kelen Immigration Services we just got our confirmation of Permanent Residence via Express Entry in less time than we expected. Kerry Molitor made our life easier and the process less stressful, explaining to us very clearly step by step. What I like the most about Kelen Services is their fast response.' "}
                name={'Cecilia and Ernesto'}
                show={false}
        />
        </div>
        <div>
        <ReviewCom
                img={R6}
                con={" 'Kerry’s service was outstanding!! She was very knowledgeable and her advice was always accurate and personalized! Today I celebrate getting my PR and a big part of it is Kerry’s support! Thank you Kerry!' "}
                name={'Sebastián'}
                show={false}
        />
        </div>
      
       
        <div>
        <ReviewCom
                img={R4}
                con={" 'I had an exceptional experience with Kerry as my immigration consultant for my Permanent Residency application. Her in-depth knowledge and attention to detail made the process smooth and stress-free. Kerry was always available to answer questions and went above and beyond in her support. Her professionalism and genuine care for her clients truly set her apart. I highly recommend Kerry.' "}
                name={'Majid'}
                show={false}
        />
        </div>
        <div>
        <ReviewCom
                img={R5}
                con={" 'It was really great to work with Kerry from the time I applied for my study permit. She is super supportive all the time when I have any doubts. Her knowledge and expertise in the area helped a lot to process our work permits, PR application smoothly. She is very responsive and guided us through out the process correctly. I can recommend her to anyone without any doubt. Her service is amazing!' "}
                name={'Induni'}
                show={false}
        />
        </div>
        <div>
        <ReviewCom
                img={R2}
                con={" 'I would recommend Kerry Molitor to anyone who has an immigration problem. She is very helpful and responsive to her clients. After my PGWP application being unfairly declined because of the technical glitch, Kerry was there to fight for my cause. It was reconsidered and I got an approval in a two weeks! A huge weight has been lifted off my shoulders and for this, I am truly grateful. Many thanks to you Kerry for your great job!' "}
                name={'Tatiana'}
                show={false}
        />
        </div>
       
      </Slider>
    </div>
  );
}

export default Responsive;
