
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Pages/Home/Index';
import Privacy from './Pages/Privacy';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Home/>} path='/'/>
        <Route element={<Privacy/>} path='/privacy'/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
