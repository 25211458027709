import React, { useEffect, useState } from 'react'
import Logo from '../images/Logo_INC_MASTER_Colour.jpg'
import { Link } from 'react-router-dom';
function Navbar2() {
    const [navbar, setNavbar] = useState(false);
    const [clientWindowHeight, setClientWindowHeight] = useState("");
    const [changeBg, setChangeBg] = useState(false)
    useEffect(() => {
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    });
   const active = 'px-2 py-1 ml-[21px]  transition ease-in-out text-[16px] border-[#343373] font-[300] border-2 rounded-[12px] text-[#343373]'
   const inActive = 'px-2 py-1 transition ease-in-out ml-[21px] text-[#343373] font-[300] text-[16px] hover:border-[#343373] hover:border hover:rounded-[12px] hover:text-[#343373]'
    const handleScroll = () => {
      setClientWindowHeight(window.scrollY);
    };
  
    useEffect(() => {
      let backgroundTransparacyVar = clientWindowHeight / 610;
      if (backgroundTransparacyVar < 1) {
        setChangeBg(true)
      }
      else {
        setChangeBg(false)
      }
    }, [clientWindowHeight])
    const [activeItem, setActiveItem] = useState(null);

    const handleNavClick = (id) => {
      setActiveItem(id); // Set the clicked item's id as active
    };
    useEffect(() => {
      const currentHash = window.location.hash;
      if (currentHash) {
        setActiveItem(currentHash);
      }
    }, []);
  return (
    <div className={changeBg ? `w-full  bg-white fixed  z-40    ` : `w-full z-40 fixed  shadow-lg bg-white   `}>
        <div className="">
        <div>
          
          
            <div className="  flex items-center justify-between px-[36px] sm:px-[86px] py-3 md:py-5">
            <Link to='/' >
              <img src={Logo} width='300' height='300' className=" inline-block pl-6 " alt=" logo" />
            </Link>
              <div className=" hidden sm:flex flex-col pr-3 justify-center">
              <div className='flex'>
                <ul className=' flex'>
                  <Link to={'/'} className={inActive}>Home</Link>
                </ul>
              </div>
              </div>
            </div>
         
      
       
        </div>

        </div>
    </div>
  )
}

export default Navbar2