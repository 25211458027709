import React, { useRef } from 'react'
import Inputtt from '../SelectValue/Inputtt'
import Textarea from '../SelectValue/TextArea'
import Button from '../SelectValue/Button'
import S1 from '../../images/soc1.svg'
import S2 from '../../images/soc2.svg'
import S3 from '../../images/soc3.svg'
import S4 from '../../images/soc4.svg'
import S5 from '../../images/soc5.svg'
import Loc from '../../images/mail9.svg'
import Phone from '../../images/spp.svg'
import emailjs from '@emailjs/browser';
function Contact() {
  const form = useRef();
  

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_kp9f3we', 'template_ll1oyye', form.current, {
        publicKey: 'j0bW3spkUUTiiSbbo',
      })
      .then(
        () => {
          alert('Mail sent')
          window.location.reload();
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };
  return (
    <div id='contact' className='sm:pt-[96px] sm:px-[90px] sm:pb-[60px] pb-[24px]  px-8 pt-[48px]'>
    <h1 className='font-[900] text-[#343373] sm:text-[64px] text-start text-[32px]'>Contact us</h1>
    
      <div className=' grid sm:grid-cols-3 gap-6 mx-auto'> 
      <div className=' mt-5'>
      <p className=' font-[700] text-[20px] mb-3'>Call us</p>
        <div className=' flex gap-3 mb-3'><img src={Phone} alt='Phone' height={20} width={20} className=' inline-block'/> <p className=' font-[400] text-[#343373]'>1-647-718-9675</p></div>
        <div className=' flex gap-3 mb-3'><img src={Loc} alt='Mail' height={20} width={20} className=' inline-block'/> <p className=' font-[400] text-[#343373]'>info@kelenservices.com</p></div>
      </div>
        <div className=' sm:col-span-2 sm:w-[75%]'>
        <form ref={form} onSubmit={sendEmail}>
            <Inputtt
                label={'Name'}
                name={'name'}
            />
             <Inputtt
                label={'Email address'}
                name="email_id"
            />
            <Inputtt
                label={'Subject'}
                name="subject"
            />
            <Textarea
                label={'Message'}
                name="message"
            />
            <div className=' grid grid-cols-1 mt-7'>
                    <Button
                        name={'Send'}
                    />

            </div>
            {/* <div className=' flex justify-center mt-4 gap-3'>
            <img src={S1} alt='S1'/>
            <img src={S2} alt='S2'/>
            <img src={S3} alt='S3'/>
            <img src={S4} alt='S4'/>
            <img src={S5} alt='S5'/>
        </div> */}
        </form>
        </div>
      </div>
    </div>
  )
}

export default Contact