import React from 'react'
import Navbar from '../../Components/Navbar'
import Topsec from '../../Components/Privacy/Topsec'
import Content from '../../Components/Privacy/Content'
import Footer from '../../Components/Footer'
import Navbar2 from '../../Components/Navbar2'

function Privacy() {
  return (
    <div>
        <Navbar2/>
        <Topsec/>
        <Content/>
        <Footer/>
    </div>
  )
}

export default Privacy